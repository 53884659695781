.containerAlert {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s all;
}

.alert {
  width: 70%;
  background-color: white;
  padding: 2rem 1rem 0;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.alertTitle {
  text-align: center;
  text-transform: uppercase;
  margin: 1rem 0 0;
  padding: 0;
  font-size: 25px;
}
.alertTitleError {
  color: red;
}
.alertTitleSuccess {
  color: green;
}

.alertDetail {
  text-align: center;
  font-size: 18px;
  padding: 1rem 0;
}
