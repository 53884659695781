#formDatosSeguridadContainerDesktop {
  position: fixed;
  top: 97vh;
  left: 14%;
  display: flex;
}

#formDatosPersonalesContainerDesktop {
  position: fixed;
  left: 14%;
  top: 38vh;
}

#formDatosPersonalesContainerTablet {
}

#formDatosPersonalesContainerMobile {
}

#formDatosContactoContainerDesktop {
}

#step-1 {
  position: relative;
  width: 75%;
}

#quiebreNacDni {
  left: 85vh !important;
  top: -45vh !important;
}

#BotonSiguiente {
  position: fixed;
  bottom: 10vh;
  right: 10%;
}

#FormLabelNumeroDocumento {
  width: 93%;
}

#RegDia {
}

#RegMes {
}
